// @flow
import React from "react";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { bgColors, colors, numberStrings } from "./constants";

const CenteredDiv = styled.div`
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  font-size: 24px;
  width: 100%;
  color: ${({ color }) => color};
  background: ${({ bgColor }) => bgColor};
  padding: 5px;
`;

type Props = {|
  winner: number,
  onRestart: () => mixed,
  onStrategyChange: (?string) => mixed,
|};

export default function VictoryScreen({
  winner,
  onRestart,
  onStrategyChange,
}: Props) {
  return (
    <>
      <Row>
        <CenteredDiv color={colors[winner]} bgColor={bgColors[winner]}>
          Player {numberStrings[winner]} has won! Congratulations!
        </CenteredDiv>
      </Row>
    </>
  );
}
